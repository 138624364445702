import { AuthPath } from 'auth/paths'
import { LazyRouteObject } from 'router/utils/types'

const AuthRoutes: LazyRouteObject[] = [
  {
    path: AuthPath.Authorize,
    Component: () => import('auth/routes/authorize'),
  },
]

export default AuthRoutes
